// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./main"

import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialisierung
document.addEventListener('DOMContentLoaded', () => {
  AOS.init();
});
